.hide,
.none {
    display: none;
}
input {
    font-family:'Microsoft YaHei','Heiti SC';
    outline: none;
}
/*单行文字隐去*/
.text-one-hidden{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/**多行溢出（超出省略） **/
.text-two-dot{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}
/*单行状态条*/
.one-toolbar{
    line-height:35px;
    height:35px;
    border-left:0px solid rgb(231, 149, 149); 
    padding-right:4px;
    padding-left:15px;
    background: #67c23a;
    color :#fff;
    font-family:'Microsoft YaHei','Heiti SC';
    font-size: 14px;
    margin-bottom:10px;
    .spanRow{
        margin-left:5px;
        font-weight:600;
        font-size:14px;
        color:white;
        line-height:35px;
    }
}
.item-width  {
    width: 32%;
    height:25px;
} 
