


































































































































  .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 2px !important ;
            font-size:11px;
        }   
    }

.el-menu--collapse .el-submenu__title span{
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
  .el-menu--collapse .el-submenu__icon-arrow{
    display: none;
  }
 
 
 .el-menu-vertical:not(.el-menu--collapse) {
  width: 250px;
 }
 
 .el-menu-vertical{
  box-sizing: border-box;
 
}
  .el-menu-item {
    height: 45px !important;
    line-height: 45px !important;
    color:#062a5f;
    background-color: transparent !important;

  }
  //下面是billy加的-不起作用
  .el-menu-item.is-active {
    color:#8a1c1c;
    font-weight:200;

  }
  //下面是billy加的-不起作用
  .itemName-MenuLeft.is-active {
    color:#8a1c1c;
    font-weight: bold;
  }
  // .el-menu-item.is-active {
  //   background: #dce2f1 !important;
  //   font-weight:600;
  //   border-radius: 8px 0px 0px 8px;
  //   i {
  //       color: #2a62ce !important;
  //   }
  // }
  //有可能是有用的。

//billy 
  .el-menu-item:hover {
    background: #68b0fd !important;
    font-weight:800;
    border-radius: 3px 0px 0px 3px;
    color:white;
  }

.el-submenu__title {
    height: 50px;
    line-height: 45px;
    background-color: #f5f7f9 !important;
    i {
        width: 16px;
        height: 16px;
        margin-right: 15px;
        color: #094663 !important;
    }
    i:hover{
      color:#42053e !important;
      //background-color :rgb(144, 170, 241);
    }
  }
  //  billy加的
  .el-submenu__title:active {
    color: #8a1c1c !important;
}
  .el-submenu__title:hover {
    color: #e6a23c !important;
}
   //  billy加的  
  .el-table__body-wrapper {
    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #409eff;
    }
  }

  ::-webkit-scrollbar {
        width: 6px;
        height:6px;
    }
 
    ::-webkit-scrollbar-track {
        background-color: #e8eff5;
    }
 
    ::-webkit-scrollbar-thumb {
        background-color: #0085c8;
    }
 
    ::-webkit-scrollbar-thumb:hover {
        background-color: #0085C8;
    }
 
    ::-webkit-scrollbar-thumb:active {
        background-color: #e6a23c;
    } 
  // 纵向滚动条
  // .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
  //   opacity: 1;
  //   width: 8px; // 纵向滑块的宽度
  //   border-radius: 2px;
  //   background-color: rgba(136, 219, 255, 1);
  //   box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  // }
 
