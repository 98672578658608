html,
body {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  outline: 0;
  list-style: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "Arial Narrow", Arial, sans-serif;
  left: 0;
}
/** 覆盖iview ui框架的样式 start**/
/*  
.ivu-table th{
  background-color: unset!important;
}
.ivu-menu-dark {
    background: #fff !important;
    color: #2b85e4;
}
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened {
    background: rgb(112, 103, 103 )  !important;
    color: #2b85e4;
}
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title {
    background: #fff !important;
    color: #2b85e4;
} 
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title:hover {
    color: #fff;
    background: #2db7f5 !important;
} */
/** 覆盖iview ui框架的样式 end**/
.ivu-menu-item-active:not(.ivu-menu-submenu) {
  border-right: none;
  color: #fff;
  background: #f8f8f9 !important;
}
/** iPad **/
/** iPhone **/
