










































































































































































































































































































































































































































































































































































































































































































































































.option-bar .login-user .login-user-dropdown{
    top:46px !important;
}
.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 5px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:0px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 18px; background:white; top:10px !important; }
.el-dialog__headerbtn i { background:white;font-size: 18px;  }
.messageBox .el-message-box__content{
    border-top: 0px solid #EBEEF5;
    height: 50px;
}
.el-message-box__message p{
    line-height: 25px;
}
.messageBox .el-message-box__btns{
    padding: 20px 15px 0;
}
.messageBox .el-message-box__header{
    background:#409eff;
}
.messageBox .el-message-box__title{
    color:white ;
    font-size:14px;
}
