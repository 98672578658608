



























































.item-width  {
   width: 48%;
   color:#409EFF;
}
.row-width  {
   width: 97%;
}
  .small-table .ivu-table-cell {
      padding: 6px;
  }
  .small-table td {
      height: 0px;
  }

  .ivu-table-row-highlight td{
      background-color: #9fcaf887 !important;
  }
  
  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #9fcaf887 !important;
    color:rgb(30, 44, 90);
  }
  .el-tree-node__content:hover {    
    background-color: #9fcaf887;
  }
  .tree-scroll{
      width :180px;
      border: 1px solid #f5dbdb;
      height: 100%;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
      border-color :#3d77eb
  }
  .el-checkbox__input.is-disabled+span.el-checkbox__label{
      color:#3d77eb
  }
  .nodeRoot{
      width:20px;
      font-size:14px;
      font-weight:600;
  }
  .nodeLabel{
      font-size:12px;
      font-weight:500;
      padding-left:10px;
      color:dark-black
  } 
  .siderCls{
      background: #FFF;
      margin: 3px auto;
  } 
  .ivu-modal-content{
      height: auto !important;
      overflow-y: auto;
  }


  .el-table__body tr.current-row > td {
      background-color: #9fcaf887 !important;
  } 
  .el-table::before {
      background-color: #dfdedd;
  } 

