















































































































































































































































































   //左边菜单下的子菜单=如：产品资料库 
  .submenu-div{
      width:200px;
      font-size:14px;
      font-weight:500;
      display:flex;
      vertical-align: middle;
  }
  .submenu-div:hover{
      //background:rgb(199, 239, 247);
      color:#e6a23c;

 
  }
  .graycss{
    color:#c0c4cc;
  }
  .blackcss{
    color:#606266;
  }
  .modFont{
    color:#3399ff ;//#2d8cf0;
    font-weight: 800;
    font-size: 14px;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
 
  ::-webkit-scrollbar-track {
    background-color: #f5f7f9;
  }
 
::-webkit-scrollbar-thumb {
  background-color: rgb(0, 133, 200);
}
 
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 133, 201);
}
 
::-webkit-scrollbar-thumb:active {
  //原来是蓝色
  background-color: #e6a23c;
}
 
   .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{
      font-size: 20px; 
      background:white;
      top:5px !important;
      }

