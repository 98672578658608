.home {
  width: 100%;
  height: 100%;
  /*background-color: #f5f7f9;*/
  background-color: #f5f7f9;
  overflow: hidden;
  display: flex;
}
.home .home-right {
  z-index: 10;
  flex-grow: 1;
  position: relative;
}
.home .home-right .home-content {
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 41px 5px 1px 0px;
}
.home .home-right .home-content .paging {
  margin: auto;
  text-align: center;
}
.layout-footer-center {
  text-align: center;
  height: 20px;
  bottom: 0 ;
  vertical-align: center;
  background: #F2F6FC;
}
