


























.home-content-layout{
    background-color: white;
    box-sizing: border-box;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: auto;
    width: 100%;
    height:96%;
    max-height: 96%;
    max-height:-webkit-calc(100% - 31px);
    max-height:-moz-calc(100% - 31px);
    max-height: calc(100% - 31px);
    position: relative;
}
.footer {
    width: 100%;
    position: relative;
    bottom: 0 ;
    background:#fff;
    min-height:15px  !important;
    height:15px !important;
    vertical-align: center;
    text-align: center;
    color:#409EFF;
    font-weight: 600;
    font-size:12px;
} 
.divcolor{
    background-color: #5cadff;
}
