













































































































































































































































































































































































































































































































































































































































































































































































































  .header{
    position: absolute;
    left:0;
    right:0;
    top:0;
    z-index: 30;
    padding: 0 20px 0px 5px;
    align-items: center;
    width:100%;
    height: 40px;
    background-color: #0085C8;
    color:white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    display: flex;
    justify-content: space-between;
    .path-guide{
        display: flex;
        align-items: center;
        .icon-menu{margin-right: 20px;cursor: pointer}
        .icon-menu:hover{background-color:#f5f7f9 }
        @media only screen and (min-width: 320px) and (max-width: 767px) {
            .breadcrumb{display: none}
        }
    }
    .option-bar{
        display: flex;
        align-items: center;
        .login-user{
            display: flex;
            align-items: center;
            font-size:12px;
            font-weight:500;
            padding: 0 0 0 20px;
        }
    }
  }
  .pStyle{
    font-size: 16px;
    color: rgba(139,0,139,0.85);
    line-height: 48px;
    display: block;
    font-weight: 600;
    text-align:center ;
    margin-bottom: 16px;
  }
  .flex{
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
  }

  .line-img{
    margin-right:4px;
    width:130px;
    height:130px;
    text-align:center;
    vertical-align:middle;
    display:inline-block;
}
 
a:visited{
	color:dark-green;
}
a:hover{
	color:#f04142;
}
a:active{
	color:dark-red;
}
.item-width  {
     width: 48%;
     color:#409EFF;
  }
  .row-width  {
     width: 97%;
  }
 
